.card {
	margin-top: 15px;
}

div.integrator {
	border: 0px;
}

@media (orientation: portrait) {
	.integrator.DL_FRONT {
		background: url('./images/ghost_dlfront_portrait.png') no-repeat;
	}

	.integrator.PDF417_BARCODE {
		background: url('./images/ghost_dlfront_portrait.png') no-repeat;
	}

	.integrator.PASSPORT {
		background: url('./images/ghost_passport_portrait.png') no-repeat;
	}

	.integrator.SELFIE {
		background: url('./images/ghost_selfie_portrait.gif') no-repeat;
	}

	.integrator.SELFIE.FACE_IN_GUIDE {
		background: url('./images/ghost_selfie_portrait_green.gif') no-repeat;
	}

	.integrator.CHECK_FRONT {
		background: url('images/ghost_checkfront_portrait.png') no-repeat;
	}

	.integrator.CHECK_BACK {
		background: url('images/ghost_checkback_portrait.png') no-repeat;
	}

	.integrator.DOCUMENT {
		background: url('images/ghost_document_portrait.png') no-repeat;
	}
}

@media (orientation: landscape) {
	.integrator.DL_FRONT {
		background: url('./images/ghost_dlfront.png') no-repeat;
	}

	.integrator.PDF417_BARCODE {
		background: url('./images/ghost_dlfront.png') no-repeat;
	}

	.integrator.PASSPORT {
		background: url('./images/ghost_passport.png') no-repeat;
	}

	.integrator.SELFIE {
		background: url('./images/ghost_selfie.gif') no-repeat;
	}

	.integrator.SELFIE.FACE_IN_GUIDE {
		background: url('./images/ghost_selfie_green.gif') no-repeat;
	}

	.integrator.CHECK_FRONT {
		background: url('images/ghost_checkfront.png') no-repeat;
	}

	.integrator.CHECK_BACK {
		background: url('images/ghost_checkback.png') no-repeat;
	}

	.integrator.DOCUMENT {
		background: url('images/ghost_document.png') no-repeat;
	}
}

.capture-img {
	width: 80%;
	height: auto;
}

.loader-wrap {
	position: fixed;
	width: 120px;
	height: 120px;
	top: 50%;
	left: 50%;
	margin-left: -60px;
	margin-top: -60px;
	display: none;
}

.loader {
	border: 16px solid #f3f3f3;
	/* Light grey */
	border-top: 16px solid #3498db;
	border-bottom: 16px solid #3498db;
	/* Blue */
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.ReactCollapse--collapse {
	transition: height 500ms;
}
